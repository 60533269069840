@import "../../all-modules.global";

.input-field {
  margin: 0 0 12px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__label {
    font-weight: 500;
  }

  &__input {
    appearance: none;
    background: var(--input);
    border: 2px solid var(--icon);
    font-family: inherit;
    line-height: inherit;
    width: 100%;
    font-size: inherit;
    background-clip: padding-box;
    border-radius: 4px;
    color: var(--color);
    transition: border-color ease-in-out 0.15s;
    max-width: 372px;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
  }

  &_required &__label::before {
    content: '*';
    color: var(--red);
    margin-right: 4px;
    font-family: emoji, math, none;
    font-size: 1.2rem;
  }

  &__input:disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  /* лишние отступы у теккст */
  &__input::-webkit-search-decoration {
    appearance: none;
  }

  /* нативный крестик */
  &__input::-webkit-search-cancel-button {
    appearance: none;
  }
  &__input::-ms-clear {
    display: none;
  }

  /* сбрасываем желтый бекграунд у инпутом автозаполнения */
  &__inpit:-webkit-autofill {
    background: var(--input);
    border: 2px solid rgba(0, 0, 0, 0.05);
    box-shadow: none;
  }

  &__input:focus {
    border-color: var(--blue-dark);
    outline: var(--blue-dark);
  }

  &__input[type=number]:focus ~ div {
    max-width: 300px;
  }
}
