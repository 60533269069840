@import "./all-modules.global";

* {
  margin:0;
  padding:0;
}

*,::before,::after{
  box-sizing:inherit;
}

html {
  line-height:1.2;
  -ms-text-size-adjust:100%;
  -webkit-text-size-adjust:100%;
  position:relative;
  min-height:100%;
  height:100%;
  box-sizing:border-box;
  -ms-overflow-style:scrollbar;
  -webkit-tap-highlight-color:transparent;
  --header-height: 130px;
  --contnet-y-padding: 64px;
  --content-t-padding: 64px;
}

@media (max-width: 1044px) {
  html {
    --contnet-y-padding: 32px;
    --content-t-padding: 32px;
    --header-height: 68px;
  }
}

@media (max-width: $min-mobile-width) {
  html {
    --contnet-y-padding: 12px;
  }
}

body {
  color: var(--color);
  word-wrap: break-word;
  background-color: var(--background);
  overflow-x: hidden;
  height: 100%;
  font-size: 1rem;
  margin: 0;
  font-family: circular,Helvetica,Roboto,Arial,sans-serif;
  text-rendering: optimizeLegibility;
  font-weight:400;
  line-height:1.5;
  touch-action: manipulation;
  // needed to match the designs correctly on macOS
  // see https://github.com/vector-im/element-web/issues/11425
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-sm: 16px;
  --font-size-m: 18px;
  --font-size-l: 24px;
  --font-size-xl: 32px;
  --font-size-xxl: 56px;

  --line-height-xs: 18px;
  --line-height-s: 24px;
  --line-height-sm: 24px;
  --line-height-m: 28px;
  --line-height-l: 32px;
  --line-height-xl: 42px;
  --line-height-xxl: 62px;

    @include font-size(s);
}

.no-scroll {
  overflow: hidden;
}

body, .theme--light {
  background: #f9f9f9;
  --background: #f9f9f9;
  --green: #5cb76b;
  --dark-green: #53a557;
  --ligh-green: #7ac587;
  --orange: #ff9b00;
  --dark-orange: #f98b00;
  --red: #EE333D;
  --red-rgb: 247, 76, 80;
  --dark-red: #ee333d;
  --color: #2e3033;
  --card-color: #fff;
  --color-2: #61655c;
  --card: #fff;
  --hover-background: #f7fafd;
  --background-2: #fff;
  --brand: #f8502a;
  --brand-rgb: 248, 80, 42;
  --grey: #bcc1c5;
  --grey-rgb: 188, 193, 197;
  --blue: #85BBFFFF;
  --blue-dark: #6daeff;
  --link: #005bff;
  --link-rgb: 0, 91, 255;
  --link-hover: #0050e1;
  --light-blue: #e0f0ff;
  --light-blue-hover: #cce6ff;
  --navbar: #fff;
  --shadow-rgb: 0, 32, 51;
  --icon: #e3e9ec;
  --icon-hover: #dbe1e4;
  --input: #fff;
}

.theme--dark {
  --background: #1e2022;
  --green: #0d8248;
  --dark-green: #0e7944;
  --orange: #ea7900;
  --dark-orange: #d76f00;
  --red: #b52b1f;
  --red-rgb: 181, 43, 31;
  --dark-red: #D4382B;
  --color: rgba(255, 255, 255, 0.87);
  --card-color: #31353d;
  --color-2: #898e94;
  --card: #272b30;
  --hover-background: rgba(0, 0, 0, .2);
  --background-2: #2a2c2f;
  --brand: #f8502a;
  --brand-rgb: 248, 80, 42;
  --grey: #68696b;
  --grey-rgb: 104, 105, 107;
  --blue: rgb(93 185 255);
  --blue-dark: #2f90da;
  --link: #2883ff;
  --link-hover: #146fff;
  --light-blue: #afd9ff;
  --light-blue-hover: #86b2da;
  --navbar: #31353d;
  --shadow-rgb: 0, 0, 0;
  --icon: #333840;
  --icon-hover: #575d6b;
  --input: #2f3339;
  color: var(--color);
  background: #1e2022;
}

body.theme--tg {
  background: var(--tg-theme-bg-color);
  --background: var(--tg-theme-bg-color);
  --color: var(--tg-theme-text-color);
  --card-color: var(--tg-theme-header-bg-color);
  --color-2: var(--tg-theme-hint-color);
  --card: var(--tg-theme-header-bg-color);
  --hover-background: var(--tg-theme-header-bg-color);
  --background-2: var(--tg-theme-header-bg-color);
  --grey: rgba(var(--tg-theme-text-color), 0.8);
  --link: var(--tg-theme-link-color);
  --link-hover: var(--tg-theme-button-color);
  --navbar: var(--tg-theme-header-bg-color);
  --input: var(--tg-theme-header-bg-color);
}

@font-face {
  font-family: circular;
  src: url('/static/fonts/Roboto-Light.eot');
  src:
          url('/static/fonts/Roboto-Light.woff2') format('woff2'),
          url('/static/fonts/Roboto-Light.woff') format('woff'),
          url('/static/fonts/Roboto-Light.ttf') format('truetype'),
          url('/static/fonts/Roboto-Light.eot') format('embedded-opentype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: circular;
  src:
          url('/static/fonts/Roboto-Regular.woff2') format('woff2'),
          url('/static/fonts/Roboto-Regular.woff') format('woff'),
          url('/static/fonts/Roboto-Regular.ttf') format('truetype'),
          url('/static/fonts/Roboto-Regular.eot') format('embedded-opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: circular;
  src:
          url('/static/fonts/Roboto-Medium.woff2') format('woff2'),
          url('/static/fonts/Roboto-Medium.woff') format('woff'),
          url('/static/fonts/Roboto-Medium.ttf') format('truetype'),
          url('/static/fonts/Roboto-Medium.eot') format('embedded-opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'fontello';
  src:
          url('/static/fonts/fontello.woff2') format('woff2'),
          url('/static/fonts/fontello.woff') format('woff'),
          url('/static/fonts/fontello.ttf') format('truetype'),
          url('/static/fonts/fontello.eot') format('embedded-opentype');
  font-display: swap;
}

//prevent any zoom
:root {
  touch-action: pan-x pan-y;
  height: 100%
}

.wrapper{
  display:flex;
  flex-direction:column;
  min-height: calc(100% - var(--header-height));
}

b, strong {
  font-weight: 500;
}

em {
  font-family: monospace;
  font-style: normal;
}

.col-3,.col-sm-3,.col-sm-6,.col-sm-12,.col-sm,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-9,.col-lg{
  position:relative;
  width:100%;
  min-height:1px;
  padding-right:15px;
  padding-left:15px;
}
@media (min-width: 476px){
  .col-3,.col-sm-3,.col-sm-6,.col-sm-12,.col-sm,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-9,.col-lg{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .col-sm-3,.col-sm-6,.col-sm-12,.col-sm,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-9{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){.col-sm-3,.col-sm-6,.col-sm-12,.col-sm,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-9{
  padding-right:15px;
  padding-left:15px
}
}
@media (min-width: 1200px){
  .col-sm-3,.col-sm-6,.col-sm-12,.col-sm,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-9{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 476px){
  .col-sm-3{
    flex:0 0 25%;
    max-width:25%
  }
  .col-sm-6{
    flex:0 0 50%;
    max-width:50%
  }
  .col-sm-12{
    flex:0 0 100%;
    max-width:100%
  }
}
@media (min-width: 768px){
  .col-md-2{
    -webkit-box-flex:0;
    flex:0 0 16.666667%;
    max-width:16.666667%
  }
  .col-md-3{
    -webkit-box-flex:0;
    flex:0 0 25%;
    max-width:25%
  }
  .col-md-4{
    -webkit-box-flex:0;
    flex:0 0 33.333333%;
    max-width:33.333333%
  }
  .col-md-5{
    -webkit-box-flex:0;
    flex:0 0 41.666667%;
    max-width:41.666667%
  }
  .col-md-6{
    -webkit-box-flex:0;
    flex:0 0 50%;
    max-width:50%
  }
  .col-md-9{
    -webkit-box-flex:0;
    flex:0 0 75%;
    max-width:75%
  }
}
.nav-link:focus,.nav-link:hover{
  text-decoration:none
}
.nav-link.disabled{
  cursor:not-allowed
}
.my-2{margin-top:.5rem!important;margin-bottom:.5rem!important}
.mt-3{margin-top:1rem!important}
.mb-4{margin-bottom:1.5rem!important}
.pr-2{padding-right:.5rem!important}
.text-left{text-align:left!important}
@media (max-width: 991px){
  .hidden-md-down{
    display:none!important
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .hidden-md{
    display:none!important
  }
}

[type="checkbox"],[type="radio"]{
  box-sizing:border-box;
  padding:0
}
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button{
  height:auto
}
::-webkit-file-upload-button{
  -webkit-appearance:button;
  font:inherit
}

.content {
  flex: 1 0 auto;
  position:relative;
  max-width: 1340px + 64px + 64px;
  margin: auto;
  width: 100%;
  padding: var(--content-t-padding) var(--contnet-y-padding) 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  margin: 0;
  padding: 0;
}

/* iphone stop styling my buttons ! */
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

dialog {
  border: none;
  padding: 0;
  margin: 0;
  width: initial;
  height: initial;
  color: var(--color);
}

a {
  color: var(--color);
}

a:hover {
  text-decoration: underline;
  color: var(--color);
}

p {
  padding: 0;
  margin: 0;
  word-wrap:break-word;
}

li {
  list-style-type: none;
}

li:focus {
  border:none;
}

.alert-error {
  color: var(--red);
}

fieldset {
  border: none;
}