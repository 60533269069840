@import "./font.global";

@mixin button-type {
  &_type_info {
    --background-color: var(--light-blue);
    --text-color: #2e3033;
  }

  &_type_info:not(:disabled):not(&_loading):hover {
    --background-color: var(--light-blue-hover);
    --text-color: #2e3033;
  }

  &_type_default {
    --background-color: var(--icon);
    --text-color: var(--color);
  }

  &_type_danger {
    --background-color: var(--red);
    --text-color: #fff;
  }

  &_type_warning {
    --background-color: var(--orange);
    --text-color: var(--color);
  }

  &_type_success {
    --background-color: var(--green);
    --text-color: #fff;
  }

  &_type_default:not(:disabled):not(&_loading):hover {
    --background-color: var(--icon-hover);
  }

  &_type_danger:not(:disabled):not(&_loading):hover {
    --background-color: var(--dark-red);
  }

  &_type_warning:not(:disabled):not(&_loading):hover {
    --background-color: var(--dark-orange);
  }

  &_type_success:not(:disabled):not(&_loading):hover {
    --background-color: var(--dark-green);
  }
}

@mixin button-size {
  &_size_xs {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 18px;
  }

  &_size_s {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
  }

  &_size_sm {
    padding: 8px 24px;
    @include font-size(m);
  }

  &_size_m {
    padding: 10px 24px;
    @include font-size(m);
  }
}

@mixin shadow($rgb: var(--shadow-rgb)) {
  box-shadow: 0 8px 18px rgba($rgb, 0.12), 0 4px 4px rgba($rgb, 0.04);
}

@mixin show-scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--grey);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}
